import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dots2 from "../../images/it-staff/Group4.png"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import Group2 from "../../images/it-staff/Group2.png"
import cir from "../../images/custom-web-application/cir.svg"
import for1 from "../../images/it-staff/for.svg"
import stafff from "../../images/android-App-Development/hireCir.svg"
import hireArrow from "../../images/android-App-Development/hireArrow.svg"
import recc from "../../images/android-App-Development/recc.svg"
import * as styles from "./HireAndroid.module.scss"

const HireAndroid = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <img
        src={for1}
        className={styles.for1}
        decoding="async"
        loading="lazy"
        alt="background"
      />

      <img
        decoding="async"
        className={styles.cir}
        loading="lazy"
        src={cir}
        alt="circle"
      />

      <img
        decoding="async"
        className={styles.dots1}
        loading="lazy"
        src={dots2}
        alt="dots"
      />

      <img
        decoding="async"
        className={styles.dots3}
        loading="lazy"
        src={Frame}
        alt="dots"
      />

      <img
        decoding="async"
        className={styles.Group}
        loading="lazy"
        src={Group2}
        alt="dots"
      />

      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards.map((e, i) => (
            <Col lg={6} md={6} sm={12} xs={12} key={i}>
              <div
                className={
                  i === 0 ? styles.card1 : i === 3 ? styles.card4 : styles.card
                }
              >
                <img
                  src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                  alt={e?.title}
                />
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
          <img
            decoding="async"
            className={styles.well}
            loading="lazy"
            src={dots2}
            alt="dots"
          />
          <img
            decoding="async"
            className={styles.wellRec}
            loading="lazy"
            src={recc}
            alt="dots"
          />
        </Row>
        <div className={styles.keyBtn}>
          {strapiData?.buttons[0] && (
            <Link
              to={strapiData?.buttons[0]?.url}
              className="btn_black_border_banner"
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>

        <img
          decoding="async"
          className={styles.staffRec}
          loading="lazy"
          src={stafff}
          alt="staff"
        />

        <img
          decoding="async"
          className={styles.hireArrow}
          loading="lazy"
          src={hireArrow}
          alt="staff"
        />
      </Container>
    </div>
  )
}

export default HireAndroid
