// extracted by mini-css-extract-plugin
export var SliderWidth = "Build-module--SliderWidth--c369e";
export var arrowImg = "Build-module--arrowImg--4ed55";
export var card = "Build-module--card--af9b2";
export var card1 = "Build-module--card1--05640";
export var card3 = "Build-module--card3--eaac7";
export var card4 = "Build-module--card4--72b2d";
export var cardWrapper = "Build-module--cardWrapper--b7fae";
export var cards = "Build-module--cards--82cec";
export var description = "Build-module--description--18667";
export var fineBg = "Build-module--fineBg--0689e";
export var heading = "Build-module--heading--b2bd2";
export var headingPremium = "Build-module--headingPremium--76538";
export var iconContainer = "Build-module--iconContainer--0ebc8";
export var iconContainerLeft = "Build-module--iconContainerLeft--74f55";
export var portfolioArrowIcon = "Build-module--portfolioArrowIcon--9b5d4";
export var portfolioArrowIconCover = "Build-module--portfolioArrowIconCover--93ca6";
export var portfolioArrowRightIconCover = "Build-module--portfolioArrowRightIconCover--87e94";
export var premiumBg = "Build-module--premiumBg--c514b";
export var premiumImg = "Build-module--premiumImg--34360";
export var providingImg = "Build-module--providingImg--c7d38";
export var teamButton = "Build-module--teamButton--f3702";
export var teamWork = "Build-module--teamWork--def96";
export var trailBg = "Build-module--trailBg--7b673";