import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData, breadCrumb }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.banner}>
      <Container>
        <nav className="mb-4">
          <Link to={`/`} className="text-dark">
            Home
          </Link>
          {" » "}
          {breadCrumb?.map((val, i) =>
            val?.url !== "#" ? (
              <React.Fragment key={i}>
                <Link to={`/${val?.url}/`} className="text-dark">
                  {val?.title}
                </Link>
                {" » "}
              </React.Fragment>
            ) : (
              <span className="text-dark">{val?.title}</span>
            )
          )}
        </nav>
        <Row className="align-items-center">
          <Col lg={6} md={12}>
            <div className={styles.heading}>
              <h1 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
              <div>
                <ul className="pr-2 pr-md-0">
                  {strapiData?.cards &&
                    strapiData?.cards?.map((el, id) => (
                      <div className={styles.bannerUl} key={id}>
                        <Col xs={1} className="">
                          <div className={styles.liBullet} />
                        </Col>
                        <Col xs={11} className="px-0">
                          <li>{el.title}</li>
                        </Col>
                      </div>
                    ))}
                </ul>
              </div>
            </div>
            <div className={`${styles.bannerBtn} pt-4`}>
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_black_border_banner"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            className={`${styles.BannerImage} text-center text-lg-right mt-5 mt-md-0`}
          >
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                decoding="async"
                loading="lazy"
                className={styles.bannerImg}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
