// extracted by mini-css-extract-plugin
export var SliderWidth = "Snapshot2-module--SliderWidth--58563";
export var arrowImg = "Snapshot2-module--arrowImg--ad211";
export var card1 = "Snapshot2-module--card1--f6031";
export var card2 = "Snapshot2-module--card2--8a9d3";
export var card3 = "Snapshot2-module--card3--0c43d";
export var card4 = "Snapshot2-module--card4--ebed8";
export var card5 = "Snapshot2-module--card5--8ec16";
export var card6 = "Snapshot2-module--card6--01a39";
export var card7 = "Snapshot2-module--card7--d5b75";
export var card8 = "Snapshot2-module--card8--205fd";
export var card9 = "Snapshot2-module--card9--33ef4";
export var cardWrapper = "Snapshot2-module--cardWrapper--5401c";
export var cards = "Snapshot2-module--cards--26399";
export var description = "Snapshot2-module--description--f72aa";
export var fineBg = "Snapshot2-module--fineBg--6e1af";
export var heading = "Snapshot2-module--heading--c3656";
export var headingPremium = "Snapshot2-module--headingPremium--6deae";
export var iconContainer = "Snapshot2-module--iconContainer--3d440";
export var iconContainerLeft = "Snapshot2-module--iconContainerLeft--a81b7";
export var portfolioArrowIcon = "Snapshot2-module--portfolioArrowIcon--ed86d";
export var portfolioArrowIconCover = "Snapshot2-module--portfolioArrowIconCover--351f9";
export var portfolioArrowRightIconCover = "Snapshot2-module--portfolioArrowRightIconCover--0853e";
export var premiumBg = "Snapshot2-module--premiumBg--6b823";
export var premiumImg = "Snapshot2-module--premiumImg--2f535";
export var providingImg = "Snapshot2-module--providingImg--ee52c";
export var teamButton = "Snapshot2-module--teamButton--0c88c";
export var teamWork = "Snapshot2-module--teamWork--6b2f9";
export var trailBg = "Snapshot2-module--trailBg--6ee90";