import { graphql } from "gatsby"
import React from "react"
import Banner from "../../components/android-app-development/Banner"
import Build from "../../components/android-app-development/Build"
import CreateUnforgettable from "../../components/android-app-development/CreateUnforgettable"
import Experience from "../../components/android-app-development/Experience"
import GotProject from "../../components/android-app-development/GotProject"
import HireAndroid from "../../components/android-app-development/HireAndroid"
import Outsource from "../../components/android-app-development/Outsource"
import Seamless from "../../components/android-app-development/Seamless"
import Snapshot from "../../components/android-app-development/Snapshot"
import Snapshot2 from "../../components/android-app-development/Snapshot2"
import SubmitIdea from "../../components/android-app-development/SubmitIdea"
import TechnologiesUse from "../../components/android-app-development/TechnologiesUse"
import Testimonials from "../../components/android-app-development/Testimonials"
import WorkPortfolio from "../../components/common/WorkPortfolio"
import ContactSales from "../../components/common/ContactSales"
import EngagementModels from "../../components/common/Engagement-Model/EngagementModels"
import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../../components/common/SEO_Revamp"
import Testimonials2 from "../../components/healthcare/Testimonials2"
import MainLayout from "../../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/android_app_development_company_aeb0737821.webp"
    />
  )
}
const androidappdevelopmentservices = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth < 1280) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const outsource = data?.strapiPage?.sections[1]
  const create = data?.strapiPage?.sections[2]
  const submitIdea = data?.strapiPage?.sections[3]
  const establishing = data?.strapiPage?.sections[4]
  const dayTrial = data?.strapiPage?.sections[5]
  const seamless = data?.strapiPage?.sections[6]
  const build = data?.strapiPage?.sections[7]
  const hireAndroid = data?.strapiPage?.sections[8]
  const engagment = data?.strapiPage?.sections[9]
  const tech = data?.strapiPage?.sections[10]
  const testimonials = data?.strapiPage?.sections[11]
  const faqs = data?.strapiPage?.sections[12]
  const experience = data?.strapiPage?.sections[13]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <Outsource strapiData={outsource} />
      <CreateUnforgettable strapiData={create} />
      <SubmitIdea strapiData={submitIdea} />
      {!isMobile ? (
        <Snapshot strapiData={establishing} />
      ) : (
        <Snapshot2 strapiData={establishing} />
      )}
      <GotProject strapiData={dayTrial} pageName="Android App Development" />
      <Seamless strapiData={seamless} />
      <Experience strapiData={experience} />
      <Build strapiData={build} />
      <HireAndroid strapiData={hireAndroid} />
      <EngagementModels strapiData={engagment} />
      <TechnologiesUse strapiData={tech} />
      <WorkPortfolio
        portfolioHead={"Our Portfolio Showcase"}
        portfolioDesc={"BAnakeed Mart. Shield Republic. Stakestar"}
      />
      {!isMobile ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}
      <Faqs strapiData={faqs} pageName="Android App Development" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "android-app" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default androidappdevelopmentservices
