// extracted by mini-css-extract-plugin
export var Android = "Snapshot-module--Android--1143d";
export var arrowImg = "Snapshot-module--arrowImg--24d6e";
export var boxContent = "Snapshot-module--boxContent--a7dfc";
export var cards = "Snapshot-module--cards--e611b";
export var cardsContent = "Snapshot-module--cardsContent--ab559";
export var cardsZ = "Snapshot-module--cardsZ--170d0";
export var description = "Snapshot-module--description--a68d2";
export var estabBtn = "Snapshot-module--estabBtn--3deb6";
export var explorrBtn = "Snapshot-module--explorrBtn--7f405";
export var finImg = "Snapshot-module--finImg--779a9";
export var heading = "Snapshot-module--heading--242ea";
export var tabImg = "Snapshot-module--tabImg--e18f8";
export var tabbingCards = "Snapshot-module--tabbingCards--eb419";
export var tabs = "Snapshot-module--tabs--65939";