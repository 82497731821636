import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./SubmitIdea.module.scss"

const SubmitIdea = ({ strapiData }) => {
  return (
    <div className={styles.SubmitIdea}>
      <Container>
        <Row>
          <Col lg={4} md={12}>
            <img
              src={strapiData?.secImages[0]?.url}
              alt={strapiData?.secImages[0]?.alternativeText}
              decoding="async"
              loading="lazy"
              className={styles.bannerImg2}
            />
            <div className={styles.heading}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              {strapiData?.buttons[0] && (
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="btn_white_border_yellow mt-3 "
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col lg={8} md={12}>
            <img
              src={strapiData?.secImages[0]?.url}
              alt={strapiData?.secImages[0]?.alternativeText}
              decoding="async"
              loading="lazy"
              className={styles.bannerImg}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SubmitIdea
