import { Link } from "gatsby"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row"
import Tab from "react-bootstrap/Tab"
import "../../components/android-app-development/android.scss"
import arrow from "../../images/android-App-Development/arrow.svg"
import * as styles from "./Snapshot.module.scss"

const Snapshot = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(strapiData?.cards[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className={`${styles.tabs} cardTabingAndroid`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.Android}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Fintech">
            <Row>
              <Col lg={7}>
                <Nav variant="pills" className={`navbarAndroid`}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div className={styles.tabImg}>
                          <img
                            className={styles.finImg}
                            src={
                              e?.image1 && e?.image1[0]?.localFile?.publicURL
                            }
                            alt={e?.title}
                          />
                          <div
                            className={`tabLink ${
                              activeTab === e.title ? "active" : ""
                            }`}
                            onClick={() => handleTabClick(e.title)}
                          >
                            {e?.title}
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
                <div className={styles.explorrBtn}>
                  {strapiData?.buttons[0] && (
                    <Link to={strapiData?.buttons[0]?.url}>
                      {strapiData?.buttons[0]?.title}
                    </Link>
                  )}
                </div>
              </Col>
              <Col lg={5}>
                <div className="tabs">
                  <Tab.Content className="contentBlockAndroid">
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <div
                          eventKey={`${e?.title}`}
                          key={i}
                          className={`tab-pane tabsss ${
                            activeTab === e.title ? "active" : ""
                          }`}
                        >
                          <div className={styles.boxContent}>
                            <div>
                              <h3>{e?.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: e?.subTitle,
                                }}
                              ></p>
                            </div>
                            <div className={styles.estabBtn}>
                              {e?.buttons[0]?.title && (
                                <Link
                                  to={e?.buttons[0]?.url}
                                  variant="light "
                                  className="btn_black_border"
                                >
                                  {e?.buttons[0]?.title}
                                  <img
                                    className={styles.arrowImg}
                                    src={arrow}
                                    decoding="async"
                                    loading="lazy"
                                    alt="arrow"
                                  />
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}
export default Snapshot
