// extracted by mini-css-extract-plugin
export var Group = "Outsource-module--Group--09b02";
export var arrowImg = "Outsource-module--arrowImg--f45dd";
export var card = "Outsource-module--card--7885b";
export var cardWrapper = "Outsource-module--cardWrapper--a04a1";
export var choseImg = "Outsource-module--choseImg--542c9";
export var cir = "Outsource-module--cir--0f2fc";
export var description = "Outsource-module--description--36994";
export var dots1 = "Outsource-module--dots1--04297";
export var dots2 = "Outsource-module--dots2--b0565";
export var dots3 = "Outsource-module--dots3--a33ab";
export var dots4 = "Outsource-module--dots4--638cf";
export var for1 = "Outsource-module--for1--ed4d1";
export var heading = "Outsource-module--heading--56bc5";
export var keyBtn = "Outsource-module--keyBtn--afb8d";
export var staffRec = "Outsource-module--staffRec--6e5aa";
export var subservice = "Outsource-module--subservice--62f5f";
export var teamBtn = "Outsource-module--teamBtn--4223a";