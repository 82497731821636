import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import up from "../../images/android-App-Development/upp.png"
import down from "../../images/android-App-Development/down.png"
import doots from "../../images/android-App-Development/learnDot.svg"
import cir from "../../images/custom-web-application/cir.svg"
import Frame from "../../images/mobile-app-developmemt/Frame.svg"
import dots from "../../images/mobile-app-developmemt/Group.svg"
import Vector from "../../images/softwere-development/Vector.png"
import reactangel from "../../images/softwere-development/Vector.svg"
import Vector1 from "../../images/softwere-development/Vector1.png"
import Vector2 from "../../images/softwere-development/Vector2.png"
import * as styles from "./Seamless.module.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button">
          {isCurrentEventKey ? (
            <img
              src={up}
              decoding="async"
              loading="lazy"
              className={styles.quesColorSelected}
              alt="icon"
            />
          ) : (
            <img
              src={down}
              decoding="async"
              loading="lazy"
              className={styles.quesColor}
              alt="icon"
            />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Faqs = ({ strapiData }) => {
  return (
    <div className={styles.Behold}>
      <img
        decoding="async"
        className={styles.cir}
        loading="lazy"
        src={cir}
        alt="circle"
      />

      <img
        decoding="async"
        className={styles.dots2}
        loading="lazy"
        src={dots}
        alt="dots"
      />

      <img
        className={styles.react}
        src={reactangel}
        decoding="async"
        loading="lazy"
        alt="reactangle"
      />

      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />

        <img
          decoding="async"
          className={styles.vector}
          loading="lazy"
          src={Vector}
          alt="vector"
        />

        <img
          decoding="async"
          className={styles.dots1}
          loading="lazy"
          src={dots}
          alt="dots"
        />

        <Row>
          <Col>
            <Row>
              <Col lg={6}>
                <div className="approachAcc">
                  <Accordion defaultActiveKey={`ek-0`}>
                    {strapiData?.cards &&
                      strapiData?.cards?.map((e, i) => (
                        <Card key={i} border="light">
                          <ContextAwareToggle eventKey={`ek-${i}`}>
                            <div className={styles.accImg}>
                              <img
                                decoding="async"
                                loading="lazy"
                                src={
                                  e?.image1 &&
                                  e?.image1[0]?.localFile?.publicURL
                                }
                                alt={e?.title}
                              />
                              <h3 className="mb-0 py-2" type="button">
                                {e?.title}
                              </h3>
                            </div>
                          </ContextAwareToggle>
                          <Accordion.Collapse eventKey={`ek-${i}`}>
                            <Card.Body className={styles.textColor}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: e?.description?.description,
                                }}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                          <hr style={{ margin: 0 }} />
                        </Card>
                      ))}
                  </Accordion>
                </div>
              </Col>
              <Col lg={6}>
                <div className={styles.approachImg}>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={strapiData?.secImages[0]?.url}
                    alt={strapiData?.title}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <img
          decoding="async"
          className={styles.vector1}
          loading="lazy"
          src={Vector1}
          alt="vector"
        />
      </Container>

      <img
        decoding="async"
        className={styles.dots3}
        loading="lazy"
        src={Frame}
        alt="frame"
      />

      <img
        decoding="async"
        className={styles.vector2}
        loading="lazy"
        src={Vector2}
        alt="frame"
      />

      <img
        decoding="async"
        className={styles.doots}
        loading="lazy"
        src={doots}
        alt="frame"
      />
    </div>
  )
}

export default Faqs
