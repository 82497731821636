import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Experience.module.scss"

const Outsource = ({ strapiData }) => {
  return (
    <div className={`${styles.Conversation}`}>
      <Container>
        <div className={styles.content}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <div className={styles.teamButton}>
            <Link
              to={strapiData?.buttons[0]?.url}
              variant="light "
              className="btn_white_border_yellow"
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Outsource
