// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--af8fe";
export var clientDots2 = "Testimonials-module--clientDots2--1cf1d";
export var content = "Testimonials-module--content--0735a";
export var dots3 = "Testimonials-module--dots3--07f9f";
export var hireArrow = "Testimonials-module--hireArrow--ca8d4";
export var iconContainer = "Testimonials-module--iconContainer--959ad";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--902e5";
export var imgBtn = "Testimonials-module--imgBtn--a1892";
export var person = "Testimonials-module--person--f88b5";
export var personBg = "Testimonials-module--personBg--82860";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--152f4";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--3a8de";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--fee2a";
export var row = "Testimonials-module--row--003ee";
export var section = "Testimonials-module--section--d52f5";
export var staffRec = "Testimonials-module--staffRec--43fbe";
export var testName = "Testimonials-module--testName--a8095";
export var testimonials = "Testimonials-module--testimonials--25d91";
export var testimonialsContent = "Testimonials-module--testimonialsContent--f76bd";
export var vector2 = "Testimonials-module--vector2--f63cf";