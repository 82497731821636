// extracted by mini-css-extract-plugin
export var Behold = "Seamless-module--Behold--91238";
export var accImg = "Seamless-module--accImg--e29e7";
export var approachImg = "Seamless-module--approachImg--da5c2";
export var approchDot1 = "Seamless-module--approchDot1--d8add";
export var cir = "Seamless-module--cir--838d8";
export var description = "Seamless-module--description--aaa56";
export var doots = "Seamless-module--doots--c2eac";
export var dots1 = "Seamless-module--dots1--a0e90";
export var dots2 = "Seamless-module--dots2--de08c";
export var dots3 = "Seamless-module--dots3--f08cc";
export var head = "Seamless-module--head--c3cd7";
export var heading = "Seamless-module--heading--f5c6e";
export var quesColor = "Seamless-module--quesColor--f8d72";
export var quesColorSelected = "Seamless-module--quesColorSelected--61945";
export var react = "Seamless-module--react--94418";
export var textColor = "Seamless-module--textColor--174e8";
export var vector = "Seamless-module--vector--77acb";
export var vector1 = "Seamless-module--vector1--10934";
export var vector2 = "Seamless-module--vector2--32005";