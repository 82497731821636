// extracted by mini-css-extract-plugin
export var Group = "HireAndroid-module--Group--6301f";
export var arrowImg = "HireAndroid-module--arrowImg--add64";
export var card = "HireAndroid-module--card--e6064";
export var card1 = "HireAndroid-module--card1--c5510";
export var card4 = "HireAndroid-module--card4--37fc0";
export var cardWrapper = "HireAndroid-module--cardWrapper--9dfa6";
export var choseImg = "HireAndroid-module--choseImg--33370";
export var cir = "HireAndroid-module--cir--16e36";
export var description = "HireAndroid-module--description--3b469";
export var dots1 = "HireAndroid-module--dots1--e232c";
export var dots2 = "HireAndroid-module--dots2--b71be";
export var dots3 = "HireAndroid-module--dots3--d6fef";
export var dots4 = "HireAndroid-module--dots4--bbfdc";
export var for1 = "HireAndroid-module--for1--6643a";
export var heading = "HireAndroid-module--heading--7336f";
export var hireArrow = "HireAndroid-module--hireArrow--5340d";
export var keyBtn = "HireAndroid-module--keyBtn--3c2ae";
export var staffRec = "HireAndroid-module--staffRec--ca6c5";
export var subservice = "HireAndroid-module--subservice--3751b";
export var teamBtn = "HireAndroid-module--teamBtn--39ef5";
export var well = "HireAndroid-module--well--fbb20";
export var wellRec = "HireAndroid-module--wellRec--7a17f";