// extracted by mini-css-extract-plugin
export var Behold = "CreateUnforgettable-module--Behold--6f1c2";
export var accImg = "CreateUnforgettable-module--accImg--b8f31";
export var approachImg = "CreateUnforgettable-module--approachImg--ff59a";
export var approachImg2 = "CreateUnforgettable-module--approachImg2--0e657";
export var description = "CreateUnforgettable-module--description--b7e27";
export var flexImg = "CreateUnforgettable-module--flexImg--bc67a";
export var head = "CreateUnforgettable-module--head--4b45a";
export var heading = "CreateUnforgettable-module--heading--7d978";
export var quesColor = "CreateUnforgettable-module--quesColor--69a25";
export var quesColorSelected = "CreateUnforgettable-module--quesColorSelected--3f120";
export var textColor = "CreateUnforgettable-module--textColor--cfa57";